import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from '../components/layout'
import SEO from '../components/seo'
import imgGreen from '../images/green.jpg'
import imgNextTee from '../images/next-tee.jpg'

const HomePage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoBb: file(relativePath: { eq: "logo/logo-blackbridge.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoZbraslav: file(relativePath: { eq: "logo/logo-zbraslav.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoLoreta: file(relativePath: { eq: "logo/logo-pysely.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoMstetice: file(relativePath: { eq: "logo/logo-mstetice.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoBerovice: file(relativePath: { eq: "logo/logo-berovice.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	return (
		<Layout isHomepage={true}>
			<SEO />
			<div className="container marketing">
				<div className="row featurette">
					<div className="col-md-7">
						<h2 className="featurette-heading">
							The unique offer to play golf in Prague.{' '}
							<span className="text-muted">
								Choose where to play from most popular courses.
							</span>
						</h2>
					</div>
					<div className="col-md-5">
						<img
							src={imgGreen}
							width="500"
							height="500"
							className="featurette-img img-fluid mx-auto"
						/>
					</div>
				</div>

				<hr className="featurette-divider" />

				<div className="row featurette">
					<div className="col-md-7 order-md-2">
						<h2 className="featurette-heading featurette-heading-right">
							3 rounds of golf at 5&nbsp;quality golf courses.{' '}
							<span className="text-muted">Buggy rental included.</span>
						</h2>
					</div>
					<div className="col-md-5 order-md-1">
						<img
							src={imgNextTee}
							width="500"
							height="500"
							className="featurette-img img-fluid mx-auto"
						/>
					</div>
				</div>

				<hr className="featurette-divider" />
			</div>

			<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
				<h1 className="display-4 mb-4">Prices</h1>
				<p className="lead">
					Retail prices listed below. We offer special TO prices (with
					commission).
				</p>
			</div>

			<div className="container pricing-container">
				<div className="card-deck mb-3 text-center">
					<div className="card mb-4 shadow-sm">
						<div className="card-header">
							<h4 className="my-0 font-weight-normal">Prague Golf Card</h4>
						</div>
						<div className="card-body">
							<h1 className="card-title pricing-card-title">
								270 € <small className="text-muted">/ card</small>
							</h1>
							<ul className="list-unstyled mt-3 mb-4">
								<li>3 rounds of golf</li>
								<li>Choose from 5 courses</li>
								<li>Buggy included</li>
							</ul>
							<Link
								to="/contact/"
								className="btn btn-lg btn-success"
								role="button"
							>
								Order card
							</Link>
						</div>
					</div>
					<div className="card mb-4 shadow-sm">
						<div className="card-header">
							<h4 className="my-0 font-weight-normal">Set up tournaments</h4>
						</div>
						<div className="card-body">
							<h1 className="card-title pricing-card-title">
								+60 € <small className="text-muted">/ card</small>
							</h1>
							<ul className="list-unstyled mt-3 mb-4">
								<li>
									We organize custom tournament mini series for the group at all
									3&nbsp;chosen courses
								</li>
							</ul>
							<Link
								to="/contact/"
								className="btn btn-lg btn-outline-success"
								role="button"
							>
								Contact us
							</Link>
						</div>
					</div>
					<div className="card mb-4 shadow-sm">
						<div className="card-header">
							<h4 className="my-0 font-weight-normal">Multiple cards</h4>
						</div>
						<div className="card-body">
							<ul className="list-unstyled mt-3 mb-4">
								<li className="mb-4">
									You can buy several cards, stay in Prague longer and play more
									rounds.
								</li>
								<li className="mb-4">
									The card is not transferable. It is valid for single-player
									only.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<hr className="featurette-divider" />

			<div className="about-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
				<h1 className="display-4 mb-4">About the card</h1>
				<p className="lead">
					The Prague Golf Card is a regional project built with the cooperation
					of five Prague golf courses in the center of Europe – the Czech
					Republic. It offers the highest quality golf game in Prague area for
					a&nbsp;fair price.
				</p>

				<p className="lead">
					It is up to you what type of courses you prefer and where you will
					play your three rounds of golf.
				</p>

				<div className="video mb-4 mt-4">
					<iframe
						src="https://player.vimeo.com/video/365130781?byline=0&portrait=0"
						width="1120"
						height="630"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>
				</div>

				<p className="lead">
					The package can be customized according to your needs. Extra services
					including transfers, rental of golf sets and so on are also available.
				</p>

				<p className="mt-5">
					<Link
						to="/map/"
						className="btn btn-lg btn-outline-success"
						role="button"
					>
						Map of the region
					</Link>
				</p>
			</div>

			<hr className="featurette-divider" />

			<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
				<h1 className="display-4 mb-4">Golf courses</h1>
				<p className="lead mb-5">
					Five top golf resorts in Prague are involved.
				</p>

				<div className="row text logos-battery">
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.grcm.cz/en/">
							<Img fixed={data.logoBb.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.praguecitygolf.cz/">
							<Img fixed={data.logoZbraslav.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="http://www.loretagolf.cz/">
							<Img fixed={data.logoLoreta.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.gcbr.cz/">
							<Img fixed={data.logoBerovice.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.golfmstetice.cz/">
							<Img fixed={data.logoMstetice.childImageSharp.fixed} />
						</OutboundLink>
					</div>
				</div>

				<p className="mt-5">
					<Link
						to="/courses/"
						className="btn btn-lg btn-outline-success"
						role="button"
					>
						Explore golf courses
					</Link>
				</p>
			</div>

			<hr className="featurette-divider" />

			<div className="container marketing">
				<div className="row">
					<div className="col-lg-8 mb-5">
						<h1 className="display-4 mb-4">Major card benefits</h1>
						<ul className="lead list-unstyled benefits">
							<li className="mb-3">
								Absolute flexibility with your{' '}
								<strong>choice from top golf courses</strong> in Prague
							</li>
							<li className="mb-3">
								<strong>Guaranteed tee times</strong>, the proximity of golf
								courses, easy logistics
							</li>
							<li className="mb-3">
								Excellent <strong>accessibility from the airport</strong> and
								the Prague downtown
							</li>
							<li className="mb-3">
								Golf courses <strong>15-45 minutes from Prague</strong> city
								center
							</li>
							<li className="mb-3">A single contact to complete inquiry</li>
							<li className="mb-3">A single EURO account for payment</li>
							<li className="mb-3">
								Accommodation available at PGC golf courses
							</li>
							<li className="mb-3">Possibility to arrange transportation</li>
						</ul>
					</div>

					<div className="col-lg-4 mb-5 text-left">
						<h1 className="display-4 mb-4">Other services</h1>
						<ul className="lead list-unstyled benefits">
							<li className="mb-3">Accommodation</li>
							<li className="mb-3">Restaurant</li>
							<li className="mb-3">Buggy rental (free with PGC)</li>
							<li className="mb-3">Golf sets rental</li>
							<li className="mb-3">Driving range</li>
							<li className="mb-3">Training academy</li>
							<li className="mb-3">And many more&hellip;</li>
						</ul>
					</div>
				</div>
			</div>

			<hr className="featurette-divider" />
		</Layout>
	)
}

export default HomePage
